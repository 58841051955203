<template>
    <div>
        <div class="card card-custom mb-5">
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">{{ $t("settings.heading.login") }}</h3>
                    <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("settings.subheading.login") }}</span>
                </div>
                <div class="card-toolbar">
                    <b-button class="btn btn-success mr-2" @click.prevent="saveLdapSettings" ref="save_submit">{{ $t("button.save") }}</b-button>
                </div>
            </div>

            <div class="card-body">
                <ValidationObserver ref="form">
                <b-form-checkbox v-model="settings.use_ldap" name="check-button" switch size="lg" class="d-inline" value=1 unchecked-value=0>{{ $t("settings.title.ldap_title") }}</b-form-checkbox>
                <span class="form-text text-muted">{{ $t("settings.title.ldap_subtitle") }}</span>
                <div v-if="settings.use_ldap == 1">
                    
                    <table class="table mt-10 border">
                        <tr>
                            <td>Vytvořený konfigurační soubor</td><td><code>/api/config/ldap.php</code></td><td>
                                <i class="fa fa-check text-success" v-if="settings.config"></i>
                                <i class="fa fa-times text-danger" v-else></i>
                            </td>
                        </tr>
                        <tr>
                            <td>Aktivní PHP extension</td><td><code>php_ldap.dll</code></td><td>
                                <i class="fa fa-check text-success" v-if="settings.extension"></i>
                                <i class="fa fa-times text-danger" v-else></i>
                            </td>
                        </tr>
                    </table>
                    <div v-if="settings.config">

                    <div class="row">
                            <label class="col-xl-3"></label>
                            <div class="col-lg-9 col-xl-6">
                                <h5 class="font-weight-bold mt-10 mb-6">{{ $t("settings.title.connection_settings") }}</h5>
                            </div>
                        </div>  
                        
                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label text-right" for="ldap.suffix">{{ $t("settings.title.ldap_account_suffix") }}</label>
                            <div class="col-lg-9 col-xl-6">            
                                <ValidationProvider>
                                <input 
                                    name="ldap.suffix"
                                    id="ldap.suffix"
                                    type="text" 
                                    class="form-control form-control-lg form-control-solid"
                                    v-model="settings.ldap_suffix"
                                    :placeholder="$t('settings.placeholder.ldap_account_suffix')"
                                    >    
                                </ValidationProvider>    
                            </div>
                        </div>   

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label text-right" for="ldap.hosts">{{ $t("settings.title.ldap_domain_controller") }}</label>
                            <div class="col-lg-9 col-xl-6">            
                                <ValidationProvider>
                                <input 
                                    name="ldap.hosts"
                                    id="ldap.hosts"
                                    type="text" 
                                    class="form-control form-control-lg form-control-solid"
                                    v-model="settings.ldap_hosts"
                                    :placeholder="$t('settings.placeholder.ldap_domain_controller')"
                                    >    
                                </ValidationProvider>    
                            </div>
                        </div>  

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label text-right" for="ldap.dn">{{ $t("settings.title.ldap_base_dn") }}</label>
                            <div class="col-lg-9 col-xl-6">            
                                <ValidationProvider>
                                <input 
                                    name="ldap.dn"
                                    id="ldap.dn"
                                    type="text" 
                                    class="form-control form-control-lg form-control-solid"
                                    v-model="settings.ldap_dn"
                                    :placeholder="$t('settings.placeholder.ldap_base_dn')"
                                    >    
                                </ValidationProvider>    
                            </div>
                        </div>  

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label text-right" for="ldap.port">{{ $t("settings.title.ldap_port") }}</label>
                            <div class="col-lg-3 col-xl-3">          
                                <ValidationProvider>
                                <input 
                                    name="ldap.port"
                                    id="ldap.port"
                                    type="text" 
                                    class="form-control form-control-lg form-control-solid"
                                    v-model="settings.ldap_port"
                                    :placeholder="$t('settings.placeholder.ldap_port')"
                                    >    
                                </ValidationProvider>    
                            </div>

                            <label class="col-xl-2 col-lg-2 col-form-label text-right" for="ldap.ssl">{{ $t("title.ssl") }}</label>
                            <div class="col-lg-1 col-xl-1 col-form-label">            
                                <b-form-checkbox v-model="settings.ldap_ssl" name="ldap.ssl" switch size="lg" class="d-inline" value=true unchecked-value=false></b-form-checkbox>
                            </div>
                        </div>   

                        <div class="row">
                            <label class="col-xl-3"></label>
                            <div class="col-lg-9 col-xl-6">
                                <h5 class="font-weight-bold mt-10 mb-6">{{ $t("settings.title.administrator_account") }}</h5>
                            </div>
                        </div>  

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label text-right" for="ldap.username">{{ $t("label.name") }}</label>
                            <div class="col-lg-9 col-xl-6">            
                                <ValidationProvider>
                                <input 
                                    name="ldap.dn"
                                    id="ldap.dn"
                                    type="text" 
                                    class="form-control form-control-lg form-control-solid"
                                    v-model="settings.ldap_username"
                                    :placeholder="$t('placeholder.username')"
                                    >    
                                </ValidationProvider>    
                            </div>                            
                        </div>      

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label text-right" for="ldap.password">{{ $t("label.password") }}</label>
                            <div class="col-lg-9 col-xl-6">            
                                <ValidationProvider>
                                <input 
                                    name="ldap.password"
                                    id="ldap.password"
                                    type="password" 
                                    class="form-control form-control-lg form-control-solid"
                                    v-model="settings.ldap_password"
                                    :placeholder="$t('placeholder.password')"
                                    >    
                                </ValidationProvider>  
                                <button class="col-xl-7 py-3 mt-5 btn btn-primary float-right" @click.prevent="testLdap" ref="test_ldap">{{ $t("button.test_connection") }}</button>     
                            </div>  
                        </div>                     
                    </div>
                </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  components: {     
  },
  data() {
      return {
          settings: {
            use_ldap: null,
            ldap_suffix: null,
            ldap_hosts: null,
            ldap_dn: null,
            ldap_port: null,
            ldap_username: null,
            ldap_password: null,
            ldap_ssl: null,
          }
      }
  },
  metaInfo () { 
      return { title: this.$t("settings.meta.subtitle.login")} 
  },
  beforeMount() {
    this.getLdapSettings();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("settings.meta.title"), subtitle: this.$t("settings.meta.subtitle.login") }]);
  },
  methods: {
    getLdapSettings(){
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/ldap")
        .then((response) => {
          this.settings = response.data;
          this.loading = false;
        });
    },
    saveLdapSettings(){
      // Loading spinner
      const submitButton = this.$refs["save_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.loading = true;
        ApiService.apiPost("/settings/ldap", 
        { 
          'use_ldap':  this.settings.use_ldap,
          'ldap_suffix':  this.settings.ldap_suffix,
          'ldap_hosts':  this.settings.ldap_hosts,
          'ldap_dn':  this.settings.ldap_dn,
          'ldap_port':  this.settings.ldap_port,
          'ldap_username':  this.settings.ldap_username,
          'ldap_password':  this.settings.ldap_password,
          'ldap_ssl':  this.settings.ldap_ssl,
        })
        .then(() => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
        }).catch(error => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
            checkErrors(error);
        });
    },
    testLdap(){
      this.loading = true;
      const testButton = this.$refs["test_ldap"];
      testButton.classList.add("spinner", "spinner-light", "spinner-right");
      ApiService.setHeader();
      ApiService.apiGet("/ldap/connection-test")
        .then((response) => {
          this.$toasted.info(response.data.message);
          this.loading = false;
          testButton.classList.remove("spinner","spinner-light","spinner-right");
        });
    },
  }
};
</script>
